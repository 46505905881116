import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Note variant="warning" mdxType="Note">
      <p><inlineCode parentName="p">{`eslint-plugin-primer-react`}</inlineCode>{` is experimental. Please report issues in the `}<a parentName="p" {...{
          "href": "https://github.com/primer/react"
        }}>{`primer/react`}</a>{` repository.`}</p>
    </Note>
    <h2>{`Installation`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Assuming you already have `}<a parentName="p" {...{
            "href": "https://www.npmjs.com/package/eslint"
          }}>{`ESLint`}</a>{` and `}<a parentName="p" {...{
            "href": "https://github.com/primer/react"
          }}>{`Primer React`}</a>{` installed, run:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-shell"
          }}>{`npm install --save-dev eslint-plugin-primer-react

# or

yarn add --dev eslint-plugin-primer-react
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In your `}<a parentName="p" {...{
            "href": "https://eslint.org/docs/user-guide/configuring/configuration-files"
          }}>{`ESLint configuration file`}</a>{`, extend the recommended Primer React ESLint config:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "extends": [
    // ...
    "plugin:primer-react/recommended"
  ]
}
`}</code></pre>
      </li>
    </ol>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://github.com/primer/eslint-plugin-primer-react"
      }}>{`eslint-plugin-primer-react`}</a>{` repository for a list of included lint rules.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      